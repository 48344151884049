import React, { Component } from "react";
import { Link } from "gatsby";

export default class service extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="service section" id="Service">
        <div className="container">
          <div className="section-head">
            <h2>Servicios</h2>
          </div>
          <div className="row">
            {data.edges.map((item, index) => {
              console.log("slug");
              console.log(item.node.slug);
              console.log(item.node);

              return (
                <div key={index} className="col-md-4 mb-3">
                  <div className="service-main">
                    <h3>{item.node.title}</h3>
                    <Link className="link" to={item.node.slug} />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.node.shortDesc.childMarkdownRemark.html,
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
